import { useCallback, useMemo, useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import LoadingsGrid from './LoadingsGrid'
import { Paper, Button, Box } from '@mui/material';
import BoxHeader from '../../../common/Components/BoxHeader'
import { useFetch } from '../../../hooks/useFetch';
import LoadingsCreateDialog from './LoadingsCreateDialog';
import { PaxMealFields, ServiceEntry } from '@aviation/catering-paxmealservice-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import { toast } from 'react-toastify';

export interface ILoadingsContainerProps {
    clientCode : string;
    airline : string;
    flightNo : string;
    dep : string;
    dest : string;
    flightDate : string;
}
function LoadingsContainer(props : ILoadingsContainerProps) {
    const { status: mealStatus, data: mealData = [], error: mealError } = useFetch<{ [key: string]: any }>(`/api/paxmeals/byleg/${props.flightDate}/${props.airline}/${props.flightNo}/${props.dep}/${props.dest}`, (response) => response);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [data, setData] = useState<any | undefined>(undefined);

    // Transform data coming from PaxMealService into rows to display tree like data 
    // in grid. Therefore we need a flat list.
    const aggregatedData = useMemo(() => {
        if (!data) return [];

        if(data.Entries === undefined)
            return [];

        const keys = Object.keys(data.Entries);
        const list : any[] = (keys as any[]).reduce((a, b) => [
            ...a,
            {
                Id: b,
                SsrCode: b,
                AdultCount: (Object.keys((data.Entries as any)[b])).reduce((a1, a2) =>  { return a1 + (a2 !== 'NOM' ? (data.Entries as any)[b][a2].AdultCount : 0)
                }, 0),
                ChildCount: (Object.keys((data.Entries as any)[b])).reduce((a1, a2) =>  { return a1 + (a2 !== 'NOM' ? (data.Entries as any)[b][a2].ChildCount : 0)}, 0),
                ManualCount: (Object.keys((data.Entries as any)[b])).reduce((a1, a2) =>  { return a1 + (a2 !== 'NOM' ? ((data.Entries as any)[b][a2].ManualCount ?? 0) : 0)}, 0),
                IsServiceType: true,
            },
            ...((Object.keys((data.Entries as any)[b])).map((item : string) => 
            {
                return {
                    Id: `${b}.${item}`,
                    SsrCode: item,
                    AdultCount: (data.Entries as any)[b][item].AdultCount,
                    ChildCount: (data.Entries as any)[b][item].ChildCount,
                    ManualCount: (data.Entries as any)[b][item].ManualCount ?? 0,
                    IsServiceType: false
                }
            }))
        ], []);

        list.sort((a, b) => a.Id < b.Id ? -1 : 1);
        return list;
    }, [data]);

    useEffect(() => {
        if(mealData === undefined)
            return;

        setData(mealData);
    }, [mealData]);

    const updateItem = useCallback(async(item : PaxMealFields & DynamoDbObject) : Promise<boolean> => {
        if(item.GSI1PK === undefined) {
            item.GSI1PK = `${props.clientCode}#${props.flightDate.substring(0,7)}`;
        }

        if(item.GSI1RK === undefined) {
            item.GSI1RK = props.flightDate;
        }

        if(item.PK === undefined) {
            item.PK = `${props.flightDate}#${props.airline}#${props.flightNo}#${props.dep}#${props.dest}`;
        }

        if(item.RK === undefined) {
            item.RK = 'CURRENT'
        }

        const init = {
            body: item,
            headers: {}
        };

        const url = `/api/paxmeals/byleg/${props.flightDate}/${props.airline}/${props.flightNo}/${props.dep}/${props.dest}`

        try {
            await API.put('api', url, init);
            return true;
        } catch(e) {
            console.error(e);
            return false;
        } 
    }, [props.clientCode, props.flightDate, props.airline, props.flightNo, props.dep, props.dest]);

    const createLoading = useCallback(async (ssrCode : string, serviceType : string) => {
        setOpenCreateDialog(false);
        
        if((data as any).Entries[ssrCode] === undefined)
            (data as any).Entries[ssrCode] = {};
    
        (data as any).Entries[ssrCode][serviceType] = {
            AdultCount: 0,
            ChildCount: 0,
            ManualCount: 0,
            SsrCode: serviceType
        };

        const success = await updateItem(data as PaxMealFields & DynamoDbObject);

        if(success) {
            toast.success('Pax Meal created successfully');
            setData({...data});
        }
        else
            toast.error('An error occurred while creating pax meal');

        

    }, [data, updateItem]);

    const onUpdateItem = async (code : string, entry : ServiceEntry) : Promise<boolean> => {
        if((data as any).Entries[code] === undefined)
            (data as any).Entries[code] = entry;
    
        (data as any).Entries[code][entry.SsrCode] = {
            AdultCount: entry.AdultCount,
            ChildCount: entry.ChildCount,
            ManualCount: entry.ManualCount,
            SsrCode: entry.SsrCode
        };

        const success = await updateItem(data as PaxMealFields & DynamoDbObject);

        if(success) {
            toast.success('Pax Meal saved successfully');
        }
        else
            toast.error('An error occurred while saving pax meal');

        return success;
    }

    return (
        <Grid item xs={12}>
            <Paper sx={{ p: 2 }} elevation={3}>
                <BoxHeader title='Loadings'>
                    <Button variant="contained" onClick={() => setOpenCreateDialog(true) }>Create Loading</Button>
                </BoxHeader>
                { openCreateDialog === true ? (
                    <LoadingsCreateDialog clientCode={props.clientCode} airline={props.airline} open={openCreateDialog} 
                        cancelAction={() => {setOpenCreateDialog(false)}} createAction={createLoading} />
                    ) : null 
                }
                <Box>
                    <LoadingsGrid aggregatedData={aggregatedData} status={mealStatus} error={mealError} updateAction={onUpdateItem}/>
                </Box>
            </Paper>
        </Grid>
    )
}

export default LoadingsContainer;