import { useMemo } from "react";
import { Grid, Paper } from '@mui/material';
import { useFetch } from '../../hooks/useFetch';
import { DashboardAnalytics } from '@aviation/dashboard-service-sdk';
import { useParams } from 'react-router-dom';
import DashboardChart from './DashboardChart';

export interface IDashboardChartsProps {
    dateFrom?: string;
    dateTo?: string;
}

function DashboardCharts(props : IDashboardChartsProps) {
    const { clientCode } = useParams();
    const data = useFetch<DashboardAnalytics | undefined>(`/api/dashboard/${clientCode}/from/${props.dateFrom}/to/${props.dateTo}`, (response) => response, 0, true).data;

    const haulTypes = useMemo(() => {
        return (data?.HaulTypes ?? []).sort((a, b) => a < b ? -1 : 1);
    }, [data]);

    const serviceTypes = useMemo(() => {
        return (data?.ServiceTypes ?? []).sort((a, b) => a < b ? -1 : 1);
    }, [data]);

    return (
        <Grid container spacing={3} marginLeft={0} marginTop={0.0}>
            <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                        {/* <DemoChart title="IDPS" subtitle="Synchronized Flights" height={150} color={theme.palette.info.main} /> */}
                        <DashboardChart title="IDPS" subtitle="Flights" height={150} data={data?.FlightAnalytics ?? []} lookupValues={haulTypes} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                        <DashboardChart title="Meals" subtitle="Distinguished by service type" height={150} data={data?.ServiceTypeAnalytics ?? []} lookupValues={serviceTypes} />
                    </Paper>
                </Grid>
        </Grid>
            
        
    );

};

export default DashboardCharts;