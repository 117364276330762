import { API } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Paper, TextField, Button, CircularProgress, Box, AlertTitle, Alert, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Status, useFetch } from '../../hooks/useFetch';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { CatererFields, LoadingPlanFields, ServiceTypeFields } from '@aviation/catering-masterdata-sdk';
import { DynamoDbObject } from '@aviation/catering-common';
import LoadingsContainer from './Components/LoadingsContainer';

import BoxHeader from '../../common/Components/BoxHeader'
import { LegInfoContainer } from './Components/LegInfoContainer';
import BobFreshFoodContainer from './Components/BobFreshFoodContainer';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from "react-toastify";
import CatererContainer from './Components/CatererContainer';
import WarningChip from './Components/WarningChip';
// import { CateringRouteLeg, CatererEntry } from '@aviation/catering-cateringrouteservice-sdk';

class CatererEntryClass {
    CatererId: string = '';
    ServiceName: string = '';
    IsDutyFree: boolean = false;
    ServiceTypes: string[] = [];
    LoadingTypes: string[] = [];
}

export class CateringRouteLeg {
    //PK
    ClientCode?: string = undefined
    Al?: string = undefined
    AcReg?: string = undefined
    FlightDate?: string;
    //RK
    Dep?: string = undefined
    Dest?: string = undefined

    //Catering route reference
    CateringRouteId?: string = undefined
    Route?: string = undefined

    //More leg related fields
    Nr?: string = undefined
    AcType?: string = undefined
    DepDate?: string = undefined

    //Data fields
    Caterer?: CatererEntryClass[] = undefined
    LoadingStations?: string = undefined
    LoadingPlanId?: string = undefined
    HaulType?: string = undefined
    Comment?: string = undefined


    //BNX#01.2023#
    GSI1PK?: string = undefined
    //30.01.2023
    GSI1RK?: string = undefined
}

function CateringRouteLegDetails() {
    const { clientCode, id, leg } = useParams();
    const { t } = useTranslation();

    // TODO: change resolver from (response) => response to (response) => response.Result or however the result is shaped
    const { status, data = { Legs: [] }, error } = useFetch<{ [key: string]: any }>(`/api/cateringroutes/byroute/${id}`, (response) => response);
    const { data: catererData = [] } = useFetch<(CatererFields & DynamoDbObject)[]>(`/api/masterdata/caterer`);
    const { data: serviceTypeData = [] } = useFetch<Array<ServiceTypeFields & DynamoDbObject>>(`/api/masterdata/servicetype/${clientCode}`);

    const [editData, setEditData] = useState<{ [key: string]: any }>(data);
    const [isDirty, setIsDirty] = useState(false);
    const [isUpdatingRoute, setIsUpdatingRoute] = useState(false);
    const [flightDate, setFlightDate] = useState<string | undefined>();
    // const [clientCode, setClientCode] = useState<string | undefined>();
    const [airline, setAirline] = useState<string | undefined>();

    const [legData, setLegData] = useState<CateringRouteLeg | undefined>(undefined);
    const [legCatererData, setLegCatererData] = useState<CatererEntryClass | undefined>(undefined);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { status: haulTypesStatus, data: haulTypesData = [], error: haulTypesError } = useFetch<Array<LoadingPlanFields & DynamoDbObject>>(`/api/masterdata/haultype/${clientCode}/${airline}`);

    const currentLeg = useMemo(() => {
        if (data === undefined)
            return;

        const item = (data.Legs as any[]).find((o: any) => { return (o.RK as string).endsWith(leg ?? ''); });

        if (item !== undefined) {
            const date = new Date(item.DepDate);
            setFlightDate(date.toLocaleString('nl-NL'));
        }

        return item;
    }, [data, leg]);

    const loadingStationList = useMemo(() => {
        let result : string[] = [];
        const legs: any[] = data.Legs;

        if(legs !== undefined)
            legs.forEach(o => {
                if(result.indexOf(o.Dep) === -1)
                    result.push(o.Dep);

                if(result.indexOf(o.Dest) === -1)
                    result.push(o.Dest);
            });

        return result;
    }, [data]);

    const updateLegField = useCallback(function <T>(fieldName: string, value?: T) {
        if (!legCatererData)
            return;

        const oldValue = legData![fieldName as keyof CateringRouteLeg];

        if (oldValue !== value) {
            const newData = { ...legData, [fieldName]: value } as CateringRouteLeg;
            setLegData(newData);
            setIsDirty(true);
        }

    }, [legData, legCatererData]);

    const updateLeg = useCallback(async (item: CateringRouteLeg) => {
        const init = {
            body: item,
            headers: {}
        };

        try {
            await API.put('api', `/api/cateringroutes/leg/${editData.ClientCode}/${currentLeg.Al}/${currentLeg.Nr}/${currentLeg.FlightDate}/${currentLeg.Dep}-${currentLeg.Dest}`, init);
            toast.success(`Flight saved successfully.`);
        } catch (e) {
            console.error(e);
            toast.error(`An error occurred while saving flight.`);
        }
    }, [editData, currentLeg]);

    const submitForm = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const item = legData;
        if (item === undefined)
            return;

        item.Caterer = [legCatererData ?? {} as CatererEntryClass];
        setIsUpdatingRoute(true);
        updateLeg(item!).then(() => { setIsDirty(false) }).finally(() => { setIsUpdatingRoute(false) });
    }, [legData, legCatererData, updateLeg])

    // Execute code, when data is loaded. We have to display first departure date e.g.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (status === Status.Fetched && editData.Legs.length === 0) {
            setEditData(data);
        }

        const legs: any[] = data.Legs;

        if (legs !== undefined && legs.length > 0) {
            //const client = legs[0].ClientCode;
            //setClientCode(legs[0].ClientCode);
            setAirline(legs[0].Al);
            setLegData(currentLeg);

            setLegCatererData(currentLeg.Caterer[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentLeg]);

    return (
        <Grid container spacing={3}>
            {status !== Status.Fetched ?
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: 'calc(100vh - 104px)',
                        width: '100%',
                    }}
                >
                    {status === Status.Error ?
                        <Alert severity="error">
                            <AlertTitle>{t('Oops!')}</AlertTitle>
                            <p>{t('Sorry, an unexpected error has occurred.')}</p>
                            <i>{error}</i>
                        </Alert>
                        :
                        <CircularProgress />
                    }
                </Box>
                :
                <>
                    <Grid item xs={12}>
                        <PageHeader title={((data?.Legs.length ?? 0) > 0 ? data.Route : 'Unkown') + ' | ' + currentLeg?.Al + currentLeg?.Nr + ' ' + currentLeg?.Dep + '-' + currentLeg?.Dest} subtitle={flightDate}>
                            <WarningChip warnings={editData.Warnings} />
                        </PageHeader>
                    </Grid>
                    <Grid item xs={12} md={8}>
                    <form onSubmit={submitForm}>
                        <Paper sx={{ p: 2, marginBottom:3 }} elevation={3}>
                            <BoxHeader title='Flight Properties'>
                                {!isUpdatingRoute && (<Button variant="contained" type="submit" disabled={!isDirty}>Save</Button>)}
                                {isUpdatingRoute && (<CircularProgress size={25} />)}
                            </BoxHeader>
                            <Box>
                                    <Stack direction='row' spacing={2}>
                                    <FormControl sx={{ m: 1, minWidth: 150, padding: 0, margin: 0, marginBottom:2 }} size="small">
                                        <InputLabel id="haul-type-label">Haul type</InputLabel>
                                        <Select
                                            labelId="haul-type-label"
                                            id="haul-type-select"
                                            value={legData?.HaulType ?? ''}
                                            onChange={(e) => { updateLegField('HaulType', e.target.value) }}
                                            label="Haul Type"
                                            itemScope
                                        >
                                            {haulTypesData.map((type) => (
                                                <MenuItem key={type.RK.split('#')[1]} value={type.RK.split('#')[1]}>{type.Name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ m: 1, minWidth: 150, padding: 0, margin: 0, marginBottom:2 }} size="small">
                                        <InputLabel id="loading-stations-label">Loading Station</InputLabel>
                                        <Select
                                            labelId="loading-stations-label"
                                            id="loading-stations-select"
                                            value={legData?.LoadingStations ?? ''}
                                            onChange={(e) => { updateLegField('LoadingStations', e.target.value) }}
                                            label="Loading Station"
                                            itemScope
                                        >
                                            {loadingStationList.map((station) => (
                                                <MenuItem key={station} value={station}>{station}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </Stack>
                                    <TextField
                                        type="text"
                                        variant='outlined'
                                        color='secondary'
                                        label={t('Supplier Comment')}
                                        onChange={e => updateLegField('Comment', e.target.value)}
                                        value={legData?.Comment ?? ''}
                                        fullWidth
                                        multiline
                                    />
                            </Box>
                        </Paper>
                        </form>
                        {currentLeg && <CatererContainer currentLeg={currentLeg} catererData={catererData} serviceTypes={serviceTypeData} />}
                    </Grid>

                    {/* Display flight details */}
                    {currentLeg && (<Grid item xs={12} md={4}>
                        <LegInfoContainer currentLeg={currentLeg} />
                    </Grid>)}

                    { /* Display list of SSR codes and corresponding service types */}
                    {currentLeg && clientCode && airline && data.PK && leg && (<LoadingsContainer clientCode={clientCode} flightNo={currentLeg.Nr} airline={currentLeg.Al} flightDate={currentLeg.FlightDate} dep={currentLeg.Dep} dest={currentLeg.Dest} />)}

                    { /* Display list of BobFreshFood */}
                    {currentLeg.FlightDate && airline && currentLeg.Nr && currentLeg.Dep && currentLeg.Dest && data.PK && (<BobFreshFoodContainer flightDate={currentLeg.FlightDate} airline={airline} flightNo={currentLeg.Nr} dep={currentLeg.Dep} dest={currentLeg.Dest} routePk={data.PK} />)}
                </>
            }
        </Grid >
    );
}

export default CateringRouteLegDetails;